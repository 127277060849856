<template>
  <div class="d-flex justify-content-center mt-3" @click="login">
    <div class="social-btn d-flex">
      <div class="p-2 ico-facebook"></div>
      <div class="p-2">{{ $t("sign_up_with_facebook") }}</div>
      <div class="ms-auto p-2 ico-right-arrow"></div>
    </div>
  </div>
</template>

<script>
import {accountService} from "@/apis/facebook-auth-api";
export default {
  name: "btn-facebook",
  setup(){
    return{
      login: accountService.login
    }
  }
}
</script>

<style scoped>

</style>