<template>
  <div class="d-flex justify-content-center mt-3">
    <div class="social-btn d-flex">
      <div class="p-2 ico-google"></div>
      <a :href="getGoogleUrl('/login')" class="p-2">{{$t("sign_up_with_google")}}</a>
      <div class="ms-auto p-2 ico-right-arrow"></div>
    </div>
  </div>
</template>

<script>
import {getGoogleUrl} from "@/utils/getGoogleUrl";
export default {
  name: "btn-google",
  methods: {
    getGoogleUrl: getGoogleUrl
  }
}
</script>

<style scoped>

</style>